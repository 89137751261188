export const achats = "Achats",
  animation = "Animation",
  bricolage = "Bricolage",
  comptabilité = "Comptabilité",
  communication = "Communication",
  conciergerie = "Conciergerie",
  coordination = "Coordination",
  couture = "Couture",
  cuisine = "Cuisine",
  depollution = "Dépollution",
  direction = "Direction",
  herboristerie = "Herboristerie",
  livraisons = "Livraisons",
  maraîchage = "Maraîchage",
  mobilité = "Mobilité",
  permaculture = "Permaculture",
  proprete = "Propreté",
  recyclage = "Recyclage",
  reparationPME = "Electroménager",
  reparationV = "Vélos",
  secretariat = "Secrétariat",
  smartphone = "Smartphone",
  vente = "Vente",
  tous = "Tous";

const activitiesFooterDatas = [
  "Bricolage",
  "Couture",
  "Cuisine du Monde",
  "Dépollution de bâches",
  "Herboristerie",
  "Livraison",
  "Maraîchage",
  "Mobilité",
  "Nettoyage / Propreté",
  "Permaculture",
  "Recyclage de bâches",
  "Réparation de Petit Matériel Electrique",
  "Reconditionnement de Smartphones",
  "Réparation de Vélos",
];

const arrayActivities = [
  {
    id: 1,
    name: "Bricolage",
    value: "Bricolage",
    who: "L'équipe bricolage ",
    make: "fabrique des",
    what1: "composteurs, des toilettes",
    what2: "sèches, à partir",
    what3: "de palettes recyclées.",
    image: "bricolage",
  },
  {
    id: 2,
    name: "Couture",
    value: "Couture",
    who: "Les couturières",
    make: "recyclent des tissus",
    what1: "pour créer des coussins,",
    what2: "des sacs à main, etc.",
    image: "couture",
  },
  {
    id: 3,
    name: "Cuisine",
    value: "Cuisine",
    who: "Les cuisinières du soleil",
    make: "apportent un service",
    what1: "de cuisine du monde avec",
    what2: "des plats à emporter.",
    image: "cuisine",
  },

  {
    id: 4,
    name: "Herboristerie",
    value: "Herboristerie",
    who: "Manon",
    make: "",
    what1: "",
    what2: "",
    image: "unknown",
  },

  {
    id: 5,
    name: "Livraisons",
    value: "Livraisons",
    who: "Nos livreurs",
    make: "apportent vos courses",
    what1: "à domicile sur commande",
    what2: "depuis le drive",
    what3: "d'Intermarché.",
    image: "unknown",
  },
  {
    id: 6,
    name: "Maraîchage",
    value: "Maraîchage",
    who: "L'équipe maraîchage",
    make: "prépare un terrain",
    what1: "agricole afin de fournir",
    what2: "des fruits et légumes,",
    what3: "des plantes aromatiques.",
    image: "maraichage",
  },
  {
    id: 7,
    name: "Mobilité",
    value: "Mobilité",
    who: "L'équipe mobilité douce",
    make: "accompagne les enfants",
    what1: "jusqu'à l'école Roza Park",
    what2: "",
    what3: "en triporteur ou à vélo.",
    image: "mobilités",
  },
  {
    id: 8,
    name: "Permaculture",
    value: "Permaculture",
    who: "Quentin",
    make: "",
    what1: "",
    what2: "",
    what3: "",
    what4: "",
    what5: "",
    image: "unknown",
  },
  {
    id: 9,
    name: "Propreté",
    value: "Propreté",
    who: "L'équipe propreté",
    make: "assure le nettoyage",
    what1: "des rues du Teil",
    what2: "et des alentours,",
    what3: "sensibilise les habitants",
    what4: "à l'environnement.",
    what5: "et au compostage.",
    image: "propreté",
  },
  {
    id: 10,
    name: "Recyclage",
    value: "Recyclage",
    who: "Les couturières bâches",
    make: "transforment",
    what1: "des bâches industrielles",
    what2: "pour les recycler en sacs",
    what3: "et autres objets",
    what4: "du quotidien",
    image: "couturieres-baches",
  },
  {
    id: 11,
    name: "Réparation de Petit Matériel Electrique",
    value: "Electroménager",
    who: "Jean-François",
    make: "répare et recycle",
    what1: "le petit électroménager",
    what2: "(grille-pains, cafetières,",
    what3: " radios, etc.)",
    image: "pme",
  },
  {
    id: 12,
    name: "Réparation de Vélos",
    value: "Vélos",
    who: "Alex et Romain",
    make: "réparent, recyclent",
    what1: "et redonnent vie",
    what2: "aux vélos",
    image: "rv",
  },
];

export { arrayActivities, activitiesFooterDatas };
