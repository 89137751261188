export const companiesList = [
  "AVS",
  "Le Duff",
  "Intermarché",
  "Lafarge Holcim ciments",
];
export const essList = [
  "Les Connexions",
  "Lez’arts collectif",
  "Mayesha espoir",
  "Rebond",
  "The Teil to be",
  "Le Terreau",
  "Zone 5",
];

export const collectivList = ["Mairie du Teil"];
