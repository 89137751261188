const myArray = [
  {
    id: 1,
    name: "Pierre",
    fonction1: "Direction",
    fonction2: "d'Activiteil",
  },
  {
    id: 2,
    name: "Marianne",
    fonction0: "Co-",
    fonction1: "Direction",
    fonction2: "d'Activiteil",
  },
  {
    id: 3,
    name: "Philippe",
    fonction1: "Direction",
    fonction2: "Ressources Humaines",
  },

  {
    id: 4,
    name: "Dominique",
    fonction1: "Coordination",
    fonction2: "...",
  },
  {
    id: 5,
    name: "Cécile",
    fonction1: "Coordination",
    fonction2: "Achats",
  },

  {
    id: 6,
    name: "Hélène",
    fonction1: "Coordination",
    fonction2: "Vente",
    fonction3: "et",
    fonction4: "Communication",
    image: "unknown",
  },

  {
    id: 7,
    name: "Stéphane",
    fonction1: "Facilitateur",
    fonction2: "Technique",
    fonction3: "du",
    fonction4: "Bricolage",
  },

  {
    id: 8,
    name: "Claire",
    fonction1: "Comptabilité",
    fonction2: "...",
  },
  {
    id: 9,
    name: "Fatima",
    fonction1: "Comptabilité",
    fonction2: "...",
  },
  {
    id: 10,
    name: "Olaya",
    fonction1: "Secrétariat",
    fonction2: "RH",
  },

  {
    id: 11,
    name: "Cécile",
    fonction1: "Secrétariat",
    fonction2: "de",
    fonction3: "Direction",
  },

  {
    id: 12,
    name: "Julien",
    fonction1: "Communication",
    fonction2: "et",
    fonction3: "Mobilité",
  },
  {
    id: 13,
    name: "Alexandre",
    fonction1: "Animation",
    fonction2: "Maraîchage",
    fonction3: "et",
    fonction4: "Mobilité",
  },
  {
    id: 14,
    name: "Aïcha",
    fonction1: "Achats",
    fonction2: "et",
    fonction3: "Livraisons",
  },
  {
    id: 15,
    name: "Laurent",
    fonction1: "Livraisons",
    fonction2: "et",
    fonction3: "Conciergerie",
  },
  {
    id: 16,
    name: "Gwenaelle",
    fonction1: "Livraisons",
    fonction2: "Achats",
    fonction3: "et",
    fonction4: "Conciergerie",
  },
  {
    id: 17,
    name: "Aurore",
    fonction1: "Conciergerie",
    fonction2: "...",
  },
  {
    id: 18,
    name: "Cécilia",
    fonction1: "Conciergerie",
    fonction2: "...",
  },
  {
    id: 19,
    name: "Nadia",
    fonction1: "Conciergerie",
    fonction2: "...",
  },

  {
    id: 20,
    name: "Priscilla",
    fonction1: "Conciergerie",
    fonction2: "...",
  },
  {
    id: 21,
    name: "Sylvette",
    fonction1: "Conciergerie",
    fonction2: "...",
  },
  {
    id: 22,
    name: "Ahmed",
    fonction1: "Dépollution",
    fonction2: "de",
    fonction3: "Bâches",
  },
  {
    id: 23,
    name: "Ali",
    fonction1: "Dépollution",
    fonction2: "de",
    fonction3: "Bâches",
  },
  {
    id: 24,
    name: "Mélodie",
    fonction1: "Dépollution",
    fonction2: "de",
    fonction3: "Bâches",
  },
  {
    id: 25,
    name: "Patrick",
    fonction1: "Dépollution",
    fonction2: "de",
    fonction3: "Bâches",
  },
  {
    id: 26,
    name: "Hayat",
    fonction1: "Recyclage",
    fonction2: "de",
    fonction3: "Bâches",
  },
  {
    id: 27,
    name: "Isha",
    fonction1: "Recyclage",
    fonction2: "de",
    fonction3: "Bâches",
  },
  {
    id: 28,
    name: "Mokhtaria",
    fonction1: "Recyclage",
    fonction2: "de",
    fonction3: "Bâches",
  },
  {
    id: 29,
    name: "Naïma",
    fonction1: "Recyclage",
    fonction2: "de",
    fonction3: "Bâches",
  },
  {
    id: 30,
    name: "Rachida",
    fonction1: "Recyclage",
    fonction2: "de",
    fonction3: "Bâches",
  },
  {
    id: 31,
    name: "Alex",
    fonction1: "Réparation",
    fonction2: "de",
    fonction3: "Vélos",
  },
  {
    id: 32,
    name: "Romain",
    fonction1: "Réparation",
    fonction2: "de",
    fonction3: "Vélos",
  },
  {
    id: 33,
    name: "Jean-François",
    fonction1: "Réparation",
    fonction2: "de petit",
    fonction3: "Electroménager",
  },
  {
    id: 34,
    name: "Foday",
    fonction1: "Cordonnerie",
    fonction2: "Maraîchage",
    fonction3: "et",
    fonction4: "Mobilité",
  },
  {
    id: 35,
    name: "Azeddine",
    fonction1: "Maraîchage",
    fonction2: "et",
    fonction3: "Mobilité",
  },

  {
    id: 36,
    name: "Dominique",
    fonction1: "Maraîchage",
    fonction2: "et",
    fonction3: "Propreté",
  },
  {
    id: 37,
    name: "Elisabeth",
    fonction1: "Maraîchage",
    fonction2: "et",
    fonction3: "Propreté",
  },
  {
    id: 38,
    name: "Fatiha",
    fonction1: "Maraîchage",
    fonction2: "et",
    fonction3: "Mobilité",
  },
  {
    id: 39,
    name: "Jean-Pierre",
    fonction1: "Maraîchage",
    fonction2: "...",
  },
  {
    id: 40,
    name: "Lilian",
    fonction1: "Maraîchage",
    fonction2: "...",
  },
  {
    id: 41,
    name: "Manon",
    fonction1: "Herboristerie",
    fonction2: "Mobilité",
  },
  {
    id: 42,
    name: "Mohammed-Atel",
    fonction1: "Maraîchage",
    fonction2: "...",
  },

  {
    id: 43,
    name: "Pascal",
    fonction1: "Paysagiste",
    fonction2: "et",
    fonction3: "Maraîchage",
  },
  {
    id: 44,
    name: "Quentin",
    fonction1: "Permaculture",
    fonction2: "...",
  },
  {
    id: 45,
    name: "Stéphane",
    fonction1: "Maraîchage",
    fonction2: "et",
    fonction3: "Propreté",
  },
  {
    id: 46,
    name: "Théo",
    fonction1: "Maraîchage",
    fonction2: "et",
    fonction3: "Propreté",
  },
  {
    id: 47,
    name: "Larbi",
    fonction1: "Propreté",
    fonction2: "...",
  },
  {
    id: 48,
    name: "Bakhta",
    fonction1: "Cuisine",
    fonction2: "...",
  },
  {
    id: 49,
    name: "Clarisse",
    fonction1: "Cuisine",
    fonction2: "...",
  },
  {
    id: 50,
    name: "Drifa",
    fonction1: "Cuisine",
    fonction2: "...",
  },
  {
    id: 51,
    name: "Enchati",
    fonction1: "Cuisine",
    fonction2: "...",
  },
  {
    id: 52,
    name: "Nafija",
    fonction1: "Cuisine",
    fonction2: "...",
  },
  {
    id: 53,
    name: "Samira",
    fonction1: "Cuisine",
    fonction2: "...",
  },
  {
    id: 54,
    name: "Zalihati",
    fonction1: "Cuisine",
    fonction2: "...",
  },
  {
    id: 55,
    name: "Christelle",
    fonction1: "Couture",
    fonction2: "...",
  },

  {
    id: 56,
    name: "Harmony",
    fonction1: "Couture",
    fonction2: "...",
  },
  {
    id: 57,
    name: "Luljeta",
    fonction1: "Couture",
    fonction2: "...",
  },
  {
    id: 58,
    name: "Ouhahiba",
    fonction1: "Couture",
    fonction2: "...",
  },
  {
    id: 59,
    name: "Véronique",
    fonction1: "Couture",
    fonction2: "...",
  },

  {
    id: 60,
    name: "Kader",
    fonction1: "Polyvalent",
    fonction2: "...",
  },
  {
    id: 61,
    name: "Arnaud",
    fonction1: "Magasinier",
    fonction2: "Rattaché",
    fonction3: "au",
    fonction4: "Bricolage",
  },
  {
    id: 62,
    name: "Jimmy",
    fonction1: "Magasinier",
    fonction2: "Bricolage",
  },
  {
    id: 63,
    name: "Béatrice",
    fonction1: "Bricolage",
    fonction2: "...",
  },

  {
    id: 64,
    name: "Hasan",
    fonction1: "Bricolage",
    fonction2: "et",
    fonction3: "Mobilité",
  },

  {
    id: 65,
    name: "Eddy",
    fonction1: "Bricolage",
    fonction2: "...",
  },
];

export default myArray;
